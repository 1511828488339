<template>
  <div>
    <b-row align-h="center" v-if="dataLoading">
      <b-spinner
        variant="primary"
        type="grow"
        class="m-5"
        style="width: 4rem; height: 4rem;"
        label="loading"
      ></b-spinner>
    </b-row>
    <b-row v-else>
      <b-col md="6" v-for="(item, ind) in currentData" :key="ind">
        <b-card>
          <b-row>
            <b-col md="12">
              <b-form-group invalid-feedback="Button Title is required.">
                <div class="d-flex justify-content-between flex-wrap pb-50">
                  <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                    >Title</label
                  >
                  <div class="d-flex flex-wrap">
                    <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                      >Active:</label
                    >
                    <b-form-checkbox
                      v-model="item.isActive"
                      switch
                      class="mr-0 ml-1"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model.trim="item.title"
                  placeholder="Enter title"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Details"
                invalid-feedback="Details is required."
              >
                <div
                  :id="`quill-toolbar-${ind}`"
                  class="d-flex border-bottom-0"
                >
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model="item.details"
                  :options="getOptions(ind)"
                  class="border-top-0"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Button Title"
                invalid-feedback="Button Title is required."
              >
                <b-form-input
                  v-model.trim="item.buttonTitle"
                  placeholder="Enter button title"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Redirect To"
                invalid-feedback="Redirect To is required."
              >
                <v-select
                  v-model="item.actionType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="actionOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="item.actionType == 'page'">
              <b-form-group label="Page" invalid-feedback="Page is required.">
                <v-select
                  v-model="item.pageID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="Select page"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" v-else-if="item.actionType == 'url'">
              <b-form-group label="URL" invalid-feedback="URL is required.">
                <b-form-input v-model.trim="item.url" placeholder="Enter url" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col v-if="currentData.length > 0" md="12">
        <b-row align-h="center">
          <b-col md="4">
            <b-button
              variant="success"
              @click="save()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    quillEditor,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      saving: false,
      dataLoading: false,
      myObj: {},
      currentData: [],
      actionOptions: [
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
      ],
      pageOptions: [],
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
    }
  },
  methods: {
    getOptions(ind) {
      return {
        modules: {
          toolbar: `#quill-toolbar-${ind}`,
        },
        placeholder: "Enter details here",
      };
    },
    checkData() {
      let state = true;
      this.currentData.forEach((el) => {
        if (
          el.title == "" ||
          //   (el.buttonTitle == "" && el.details == "") ||
          (el.buttonTitle != "" && el.actionType == "") ||
          (el.buttonTitle != "" && el.actionType == "page" && el.pageID == 0) ||
          (el.buttonTitle != "" && el.actionType == "url" && el.url == "")
        ) {
          state = false;
        }
      });
      return state;
    },
    save() {
      if (this.checkData() == true) {
        this.myObj.logo = JSON.stringify(this.currentData);
        this.saving = true;
        if (this.myObj.id == 0) {
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/Logos",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvToast.toast("Details saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        } else {
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Logos/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvToast.toast("Details saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        }
      } else {
        this.$bvToast.toast("Please add the details correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Logos",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          let obj = res.data.data.find((el) => el.type == "bank");
          //   console.log(obj);
          if (obj) {
            this.myObj = obj;
            this.currentData = JSON.parse(this.myObj.logo);
          } else {
            this.myObj = {
              id: 0,
              logo: "",
              type: "bank",
            };
            this.currentData = [
              {
                title: "",
                buttonTitle: "",
                actionType: "",
                pageID: 0,
                url: "",
                details: "",
                icon: "",
                isActive: true,
              },
              {
                title: "",
                buttonTitle: "",
                actionType: "",
                pageID: 0,
                url: "",
                details: "",
                icon: "",
                isActive: true,
              },
              {
                title: "",
                buttonTitle: "",
                actionType: "",
                pageID: 0,
                url: "",
                details: "",
                icon: "",
                isActive: true,
              },
            ];
          }
          //   console.log(this.currentData);
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = this.items;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style></style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

#quil-content ::v-deep {
  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
